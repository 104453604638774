import { template as template_01919fbf13ef4bf2a70ccb5c34151929 } from "@ember/template-compiler";
const FKControlMenuContainer = template_01919fbf13ef4bf2a70ccb5c34151929(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
